<template>
	<div class="client-secret">
		<el-row class="function-area">
			<el-button type="primary" size="mini" @click="showAddDialog">添加密钥</el-button>
		</el-row>
		<el-table :data="clientSecrets" style="width: 100%">
			<el-table-column prop="id" label="ID" width="50"></el-table-column>
			<el-table-column prop="secretName" label="密钥名称" width="150"></el-table-column>
			<el-table-column prop="running" label="是否有效" width="100">
				<template slot-scope="scope">
					<el-tag v-if="scope.row['isValid']" type="success">有效</el-tag>
					<el-tag v-else type="warning">无效</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="maxPerDay" label="每日最大" width="100"></el-table-column>
			<el-table-column prop="updateTime" label="更新时间" width="180"></el-table-column>
			<el-table-column label="操作" width="120">
				<template slot-scope="scope">
					<el-button @click.native.prevent="showEditDialog(scope.$index)" type="text" size="small">修改</el-button>
					<el-button @click.native.prevent="showSecretInfo(scope.$index)" type="text" size="small">查看密钥</el-button>
				</template>
			</el-table-column>
		</el-table>

		<el-row class="note">
			注：<a href="https://console.cloud.google.com/apis/credentials?project=starry-compiler-229803" target="_blank">对应的密钥配置地址。</a>
			<span>可以创建多个项目，增加上传次数。</span>
		</el-row>

		<el-dialog :title="addOrEdit === 'ADD' ? '添加密钥' : '修改密钥'" :visible.sync="addOrEditDialog">
			<el-form :model="addOrEditFormData" :rules="addOrEditFormRules" ref="addForm" label-width="110px" size="mini">
				<el-form-item label="密钥名称" prop="secretName">
					<el-input v-model="addOrEditFormData.secretName" placeholder="请输入密钥名称"></el-input>
				</el-form-item>
				<el-form-item label="密钥" prop="secret">
					<el-input type="textarea" v-model="addOrEditFormData.secret" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="每日最大" prop="isValid">
					<el-input-number v-model="addOrEditFormData.maxPerDay" :min="1"></el-input-number>
				</el-form-item>
				<el-form-item label="是否有效" prop="isValid">
					<el-switch v-model="addOrEditFormData.isValid" :active-value="1" :inactive-value="0"></el-switch>
				</el-form-item>
				<el-form-item label="备注" prop="note">
					<el-input v-model="addOrEditFormData.note"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="toAddOrEditTask('addForm')" :loading="addOrEditLoading">确 定</el-button>
					<el-button @click="toResetForm">取 消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="密钥信息" :visible.sync="secretInfoDialog" width="80%">
			<pre>{{ JSON.parse(currentSecretInfo) }}</pre>
			<div slot="footer" class="dialog-footer">
				<el-button @click="secretInfoDialog = false" size="small">关 闭</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {getAllSecrets, addNewSecret, updateSecretInfo} from "@/api/client_secret";

export default {
	name: "ClientSecret",
	data() {
		return {
			clientSecrets: [],
			currentSecretInfo: null,
			secretInfoDialog: false,

			addOrEdit: "",
			addOrEditFormData: {
				id: null, secretName: null, secret: null, maxPerDay: 5, isValid: false, note: null
			},
			addOrEditFormRules: {
				secretName: [
					{required: true, message: '请输入密钥名称', trigger: 'blur'},
					{min: 2, max: 15, message: '长度在2到15个字符', trigger: 'blur'}
				],
				secret: [{required: true, message: '请输入密钥信息', trigger: 'blur'}]
			},
			addOrEditDialog: false,
			addOrEditLoading: false
		}
	},
	created() {
		this.toGetAllClientSecrets();
	},
	methods: {
		toGetAllClientSecrets() {
			getAllSecrets().then(response => {
				if (!response['success']) return this.$message.error('获取所有Secrets：' + response.data);
				this.clientSecrets = response.data;
			}).catch((error) => {
				this.$message.error('获取所有Secrets：' + error);
			});
		},
		showAddDialog() {
			this.addOrEdit = "ADD";
			this.addOrEditDialog = true;
		},
		showEditDialog(index) {
			this.addOrEdit = "EDIT";
			this.addOrEditDialog = true;
			this.addOrEditFormData = JSON.parse(JSON.stringify(this.clientSecrets[index]));
		},
		showSecretInfo(index) {
			// 显示密钥详情
			this.secretInfoDialog = true;
			this.currentSecretInfo = this.clientSecrets[index]['secret'];
		},
		toAddOrEditTask(formName) {
			this.$refs[formName].validate((valid) => {
				if (!valid) return false;

				this.addOrEditLoading = true;
				if (this.addOrEdit === 'ADD') {
					addNewSecret(this.addOrEditFormData).then(response => {
						this.addOrEditLoading = false;
						if (!response['success']) return this.$message.error('添加密钥失败：' + response.data);
						this.$message.success('添加密钥成功');
						this.toGetAllClientSecrets();
						this.toResetForm();
					}).catch((error) => {
						this.addOrEditLoading = false;
						this.$message.error('添加密钥失败：' + error);
					});
				} else {
					updateSecretInfo(this.addOrEditFormData).then(response => {
						this.addOrEditLoading = false;
						if (!response['success']) return this.$message.error('修改密钥失败：' + response.data);
						this.$message.success('修改密钥成功');
						this.toGetAllClientSecrets();
						this.toResetForm();
					}).catch((error) => {
						this.addOrEditLoading = false;
						this.$message.error('修改密钥失败：' + error);
					});
				}
			});
		},
		toResetForm() {
			// 重置form
			this.addOrEditFormData = {
				id: null, secretName: null, secret: null, maxPerDay: 5, isValid: false, note: null
			};
			this.addOrEditDialog = false;
		},
	}
}
</script>

<style lang="less" scoped>
.client-secret {
	margin: 10px;

	.function-area {
		margin: 10px 0;
	}

	.note {
		margin-top: 30px;
		font-weight: bold;

		a {
			color: coral;
		}
	}
}
</style>