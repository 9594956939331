import request from '@/utils/request';

//获取所有Secrets
export function getAllSecrets() {
    return request({
        url: '/api/v1/secret',
        method: 'get'
    });
}

//添加新的Secret {secretName, secret, maxPerDay, isValid, note}
export function addNewSecret({secretName, secret, maxPerDay, isValid, note}) {
    return request({
        url: '/api/v1/secret',
        method: 'POST',
        data: {secretName, secret, maxPerDay, isValid, note}
    });
}

//更新Secret {id, secretName, secret, maxPerDay, isValid, note}
export function updateSecretInfo({id, secretName, secret, maxPerDay, isValid, note}) {
    return request({
        url: '/api/v1/secret',
        method: 'put',
        data: {id, secretName, secret, maxPerDay, isValid, note}
    });
}